@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/Gilroy-Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/Gilroy-SemiBold.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/Gilroy-SemiBold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/Gilroy-Medium.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/Gilroy-Bold.otf');
  font-weight: 700;
}
