@import 'variables.less';

.ant-layout.ant-layout-has-sider > .ant-layout {
  overflow: hidden;
  background: #fff;
}

// .clientDetail {
//   padding: 20px 20px 10px;
// }

// .headerTitle{
//     padding: 10px;
//     width: 100%;
//     display: block;
//     font-size: 22px;
//     margin-right: 10px;
//     color: #424242;
//     margin-bottom: 0;
//     text-transform: capitalize;
//     font-weight: 600;
//     letter-spacing: 1px;
// }

.contentSectionTitle h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.search-header {
  padding: 10px 10px;
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
}

.ant-select-dropdown-menu .ant-select-selection:hover,
.ant-select-open .ant-select-selection:focus,
.select-diamonds .ant-select-selection:hover,
.search-dimond-filter .ant-input-number:focus,
.search-dimond-filter .ant-input-number-focused,
.search-dimond-filter .ant-input-number:hover {
  border-color: @theme-primary;
  box-shadow: none !important;
}

.search-dimond-filter .ant-input-number input {
  -webkit-appearance: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

.ant-select-dropdown-menu .ant-select-dropdown-menu-item:hover {
  background-color: @theme-light;
  color: @theme-primary;
}

.search-dimond-filter {
  padding: 15px;
  min-height: calc(~'100vh - 180px');
}

.inventorySearch .widthshape {
  // flex-wrap: nowrap;
  width: 100% !important;
  background: #fff;
  padding: 0;
  border: none;
  margin-bottom: 5px;
  // height: 90px;
  overflow: hidden;

  display: flex;
  flex-wrap: wrap;
  height: auto;
  list-style: unset;
  align-items: stretch;
  gap: 0px 5px 0px 0px;

  > li {
    width: 7% !important;
    height: 96px !important;
  }
}

.inventorySearch .widthshape.show {
  height: auto;
}

.search-dimond-filter .shape-critarea ul li {
  padding: 6px;
  cursor: pointer;
  border: 1px solid #e9ebf0;
  border-radius: 3px;
  margin-right: 4px;
  background: #f8f8f8;
  max-width: 105px;
  color: #4c4d4f;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
  align-items: center;
  justify-content: center;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  // width: 97px;
  width: 10%;
  margin-bottom: 4px;
  min-height: 83px;
  display: flex;
}
// .shape-critarea ul li.show,
// .shape-critarea ul li:first-child,
// .shape-critarea ul li:nth-child(2),
// .shape-critarea ul li:nth-child(3),
// .shape-critarea ul li:nth-child(4),
// .shape-critarea ul li:nth-child(5),
// .shape-critarea ul li:nth-child(6),
// .shape-critarea ul li:nth-child(7),
// .shape-critarea ul li:nth-child(8),
// .shape-critarea ul li:nth-child(9),
// .shape-critarea ul li:nth-child(10),
// .shape-critarea ul li:nth-child(11),
// .shape-critarea ul li:nth-child(12),
// .shape-critarea ul li:nth-child(13),

// .shape-critarea ul li:nth-child(14) {
//   display: flex;
// }

// .shape-critarea ul li {
//   display: none;
// }

.MoreShape {
  border: 1px solid rgba(40, 56, 149, 0.1);
  display: block;
  width: 84px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  height: 38px;
  font-weight: 700;
  font-size: 15px;
  color: #4c4d4f;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  line-height: 36px;
}

.shape-critarea .MoreShape {
  height: 89px;
  line-height: 87px;
}

.MoreShape:hover {
  border-color: @theme-primary;
  color: @theme-primary;
}

.shape-critarea ul li:hover,
.selection-tag {
  background: @theme-light !important;
  border-color: @theme-primary !important;
}

.shape-critarea ul li:hover svg,
.selection-tag svg {
  fill: @theme-primary;
}

.shape-critarea ul li:hover path,
.selection-tag path {
  stroke: @theme-primary;
}

.shape-critarea ul li .shape-name {
  line-height: 16px;
  font-size: 12px;
  margin-top: 2px;
  color: #000;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.shape-critarea ul li:hover .shape-name {
  color: @theme-primary;
}

.selection-tag,
.selection-tag .shape-name {
  color: @theme-primary !important;
}

.MoreShape::selection {
  color: @theme-primary;
  background: transparent;
}

h3.title {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  margin-right: 15px;
  width: 124px;
  min-width: 124px;
  word-break: break-word;
  margin-bottom: 0;
}
h3.title.rapprice-range,
h3.title.crown-range {
  margin-bottom: 8px;
}

.carat-range {
  font-size: 14px;
  line-height: 18px;
  color: #4c4d4f;
  letter-spacing: 0.3px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.select-range-text .ant-input-number {
  color: #000;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e9ebf0;
  border-radius: 6px;
  font-size: 12px;
}

.select-range-text .ant-input-number:hover {
  border-color: @theme-primary;
}

.select-range-text .add-btn.carat {
  width: 36px;
  border: 1px solid #e9ebf0;
  background-color: @theme-primary;
  height: 36px;
  border-radius: 6px;
  margin-left: 4px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.select-range-text .add-btn.carat img {
  width: 12px;
  height: 12px;
}

.select-range-text .add-btn.carat:hover {
  background-color: @theme-primary !important;
}

.carat-select-block .input-group {
  text-align: center;
  display: inline-block;
  width: 80px;
  margin: 2px;
}

.carat-select-block .input-group input {
  padding: 5px;

  &:focus {
    box-shadow: none;
  }
}

.select-range-text {
  display: flex;
  margin-bottom: 6px;
}

.carat-select-block .ant-input,
.white-color > * {
  background: #f8f8f8;
  border: 1px solid #e9ebf0;
  cursor: pointer;
  font-size: 13px;
  color: #000;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.white-color > * {
  padding: 10px;
  margin-right: 4px;
  border-radius: 4px;
  width: 50px;
  display: inline-block;
  margin-bottom: 4px;
}

.carat-select-block .ant-input:hover,
.selection-box span:hover {
  border-color: @theme-primary;
  background: @theme-light;
  color: @theme-primary;
}

.d-flex {
  display: flex !important;
  align-items: center;
}

.crt-right {
  width: calc(~'100% - 124px');
}

.crt-right .select-range-text {
  display: flex;
  margin-bottom: 4px;
}

.color-block .ant-radio-inner::after {
  background-color: @theme-primary;
}

.color-block .ant-radio-wrapper:hover .ant-radio,
.color-block .ant-radio:hover .ant-radio-inner,
.color-block .ant-radio-input:focus + .ant-radio-inner,
.color-block .ant-radio-checked .ant-radio-inner,
.search-dimond-filter .ant-input:hover {
  border-color: @theme-primary;
}

.white-color,
.fancy-color {
  height: 38px;
  overflow: hidden;
  width: 100%;
  position: relative;
  padding-right: 62px;
}

.white-color.selection-box.colorshow,
.fancy-color.selection-box.colorshow {
  height: auto;
}

.colorMoreLess {
  position: absolute;
  right: 0;
  margin-right: 25px !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.color .color-block .crt-right.show .white-color {
  height: auto;
}

.white-color.selection-box span {
  min-width: 50px;
}

.selection-box span {
  padding: 8px 12px;
  margin-right: 4px;
  border-radius: 4px;
  width: auto;
  display: inline-block;
  margin-bottom: 4px;
  background: @theme-light-grey;
  border: 1px solid @theme-border-light-grey;
  cursor: pointer;
  font-size: 13px;
  color: #000;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: capitalize;
}

.intensity-selection {
  width: calc(~'100% - 140px');

  .selection-box {
    flex-wrap: wrap;
    position: relative;
    height: 38px;
    padding-right: 58px;
    overflow: hidden;

    &.colorshow {
      height: auto;
    }
  }
}

.border-block {
  border: 2px dotted #e1e1e1;
  padding: 10px;
  margin-bottom: 6px;
}

.selection-make > * {
  background: @theme-light;
  border-radius: 50px;
  border: 1px solid @theme-primary;
  padding: 2px 10px;
  cursor: pointer;
  margin: 0 4px 0 0;
  color: @theme-primary;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.selection-make > *:hover {
  background: @theme-primary;
  color: #fff;
}

.make {
  margin-bottom: 10px;
}

.shapeBlockBox {
  align-items: flex-start;
}

.ant-layout.ant-layout-has-sider > .ant-layout {
  background: #fff !important;
}

.price-amt .ant-input-number {
  width: 49%;
}

.amount-range {
  text-align: right;
}

.search-btn {
  background: @theme-light;
  border: 1px solid @theme-primary;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.advanced-btn {
  text-align: center;
  margin-top: 10px;
}

.right-block label {
  margin-bottom: 4px;
  display: block;
  line-height: normal;
}

.advancemeasurement-range {
  margin-bottom: 20px;
  margin-bottom: 8px !important;
  margin-top: 15px;
  font-size: 18px;
  width: 100% !important;
}

.inclusiveBlock {
  .selection-box {
    span {
      width: auto;
    }
  }
}

.advancemeasurement > *:not(:last-child) {
  margin-bottom: 10px;
}

.keysymbol {
  margin-top: 10px;
}

.makeGroup {
  width: calc(~'50% - 10px');
  // width: calc(~'40% - 10px');
  margin-right: 10px;
}

.fluorescenceGroup {
  width: calc(~'25% - 10px');
  margin-bottom: 6px;
  margin-right: 10px;

  .selection-box {
    span {
      width: 50px;
    }
  }
}

.labGroup {
  width: 25%;
  // margin-right: 10px;
  margin-bottom: 6px;

  .selection-box {
    span {
      width: auto;
    }
  }
}

.sameBoxSize {
  .selection-box {
    span {
      width: 67px;
    }
  }
}

.locationBlock {
  width: 50%;
}

.brilliancyBlock,
.stageMainBlock {
  margin-bottom: 6px;
  margin-left: 10px;
  width: 50%;
}

// .selection-box {

//   .brandOptionRight {

//     span {
//       background: @theme-light;
//       border: 1px solid @theme-primary;
//       color: @theme-primary;
//       border-radius: 16px;
//     }
//   }
// }

.heartarrowBlock {
  width: 50%;
}

.tabInnerPageHeight {
  height: calc(~'100vh - 89px');
  position: relative;
}

.informtionDetailPopup {
  .ant-drawer-content-wrapper {
    width: 445px !important;
    background-color: #fff !important;

    .ant-drawer-wrapper-body {
      background-color: #fff !important;
    }

    .ant-drawer-close {
      top: 10px;
      left: -65px;
      right: auto;
      width: 36px;
      height: 36px;
      padding: 11px;
      background: #fff;
      border: none;
      outline: 0;
      border-radius: 50%;
      box-shadow: 0 1px 15px rgba(0, 0, 0, 0.07);
      stroke: #979797;
      display: flex;
      cursor: pointer;
    }
  }
}

.advanced-search .location .ant-input {
  width: 370px;
}

.right-block .ant-input-number {
  width: 100%;
}

.inventory .shapeDropdown ul {
  flex-wrap: wrap;
  border: none;
  background-color: transparent;
  padding: 0;
}

.inventory .shapeDropdown .ant-select-selection__rendered::after {
  content: none;
}

.inventory .shapeDropdown .ant-select-selection {
  padding: 8px 8px 14px;
}

.keysymbol .selection-box {
  margin-top: 4px;
}

.configurationWrapper {
  height: 100%;

  .leftSubTab {
    display: flex;
    height: 100%;

    .react-tabs__tab-list {
      background: @theme-light;
      height: 100%;
      width: 300px;
      margin-bottom: 0;
      padding: 15px 25px;
    }

    .configurationRight {
      width: calc(~'100% - 300px');
    }

    .botoomStickyBar {
      width: calc(~'100% - 300px');
    }
  }

  .addFormPlus {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: @theme-primary;
    display: flex;
    position: absolute;
    right: 20px;
    bottom: 80px;
    box-shadow: 0 0 6px 0px rgb(110, 143, 231);
    cursor: pointer;
    z-index: 1;

    img {
      width: 16px;
      height: 16px;
      margin: auto;
    }
  }
}

.searchPopupCommon {
  .popupDec {
    color: #999;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 50px;
    text-align: center;
  }

  .searchPopupTwoValue {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .from-group {
      width: 49%;
    }
  }
}

.serachTopBlock {
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  // border-bottom: 1px solid #e4e4e4;
  .TopSearchInput,
  .topSearchSelect {
    width: calc(~'100% - 158px');

    &.ant-select-open .ant-select-selection {
      box-shadow: none;
      border-radius: 0;
    }

    input {
      border: none;
      border-bottom: 1px solid #e8e8e8;
      height: 100%;
      width: 100%;

      &::placeholder {
        color: rgba(34, 34, 34, 0.6);
        font-size: 16px;
      }
    }

    .ant-select-selection {
      border: none;
      border-bottom: 1px solid #e8e8e8;
      border-radius: 0 !important;

      .ant-select-selection__placeholder {
        color: rgba(34, 34, 34, 0.6);
        font-size: 16px;
      }

      input {
        border: none !important;
        border-radius: 0 !important;
      }

      &:focus {
        outline: none;
      }
    }

    .searchTopRight {
      text-align: right;
    }

    &.TopSearchInput {
      width: 50%;
    }
  }

  .searchTopRightWrapper {
    // width: 40%;
    display: flex;
    justify-content: space-between;

    .formSelect {
      width: 50%;

      .ant-select {
        padding: 10px 0;

        .ant-select-selection {
          border-bottom: none;
        }
      }
    }
  }

  .searchTopLeftWrapper {
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 35%;
    // input {
    //   margin-top: auto;
    //   margin-bottom: auto;
    //   padding-left: 1rem;
    //   padding-right: 1rem;
    //   background: lighten(@theme-primary, 70%);
    //   border: 1px solid @theme-primary !important;
    //   border-radius: 10px !important;
    //   box-shadow: none !important;
    //   height: 2rem;
    // }

    input {
      margin-top: auto;
      margin-bottom: auto;
      border: 1px solid @theme-border-light-grey;
      // border-radius: 0.5rem;
      box-shadow: none;
      background: @theme-light-grey;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      height: 2rem;
      width: 100%;
    }
  }
}

.search-dimond-filter {
  .ant-calendar-picker {
    &:hover {
      input {
        border: 1px solid @theme-primary !important;
      }
    }

    &:focus {
      input {
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid @theme-primary !important;
      }
    }

    input:focus {
      outline: none !important;
      box-shadow: none !important;
      border: 1px solid @theme-primary !important;
    }
  }
}

.search-dimond-filter .multiSelect {
  height: 36px;
}

.search-dimond-filter .ant-select-open .ant-select-selection {
  box-shadow: none !important;
  border: 1px solid @theme-primary!important;
}

.colorTab {
  margin-top: 5px;

  a {
    font-size: 13px;
    color: #000;
    font-weight: 400;
    padding-right: 5px;
    margin-right: 5px;
    border-right: 1px solid #ddd;

    &:last-child {
      border-right: none;
    }

    &.active {
      font-weight: 600;
      color: @theme-primary;
      text-decoration: underline;
    }
  }
}

.hideShowBlock {
  background-color: #fafafa;
  border-top: 1px solid #ddd;
  padding: 15px;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  cursor: pointer;
  text-transform: uppercase;
  justify-content: space-between;

  img {
    height: 14px;
    width: 14px;
  }
}

.smallLabelInner {
  color: #000;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
  text-decoration: underline;
}

.multiSelectDropdown {
  img {
    margin-left: 5px;
    width: 12px;
    height: 12px;
  }
}

.selectOptionDropdown {
  background: #fff;
  padding: 10px;
  box-shadow: -5px -5px 16px 0px rgba(0, 0, 0, 0.09);
  border-radius: 0 0 10px 10px;

  .ant-checkbox-wrapper {
    display: block;
    margin-left: 0;
  }
}

.diffrentBlockWrapper {
  .threeBlockPart {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    border-left: 1px solid #ddd;
    padding-left: 10px;

    ul {
      margin: 0;
      margin-top: 0;
      // justify-content: center;
    }

    &:first-child {
      padding-left: 0;
      border-left: none;
    }

    .threeInnerBlock {
      padding-left: 10px;
      width: 50% !important;
      margin-right: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.antdInputBlock {
  input {
    border: 1px solid #e4e4e4;
    border-radius: 6px;
    padding: 0 11px;

    &:focus {
      border: 1px solid @theme-primary;
    }
  }
}

.justify-content-between {
  justify-content: space-between;
}

.sameBoxSize {
  .selection-box {
    &.min-width {
      span {
        min-width: 67px;
        width: auto;
      }
    }

    .fancy-selection {
      span {
        width: auto;
        border: 0;
        background-color: transparent;
        padding: 0;

        &:hover {
          background-color: transparent;
        }
      }

      .formSelect {
        width: 31%;
      }

      .ant-select-selection--multiple {
        .ant-select-selection__placeholder {
          margin-left: 10px !important;
        }
      }
    }
  }
}
