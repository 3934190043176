@import 'variables.less';
.inventoryTab {
  height: 100vh;
}
.quickSearchTable {
  width: 100%;
  overflow: auto;
  height: 100%;
  position: relative;
  .botoomStickyBar {
    padding-bottom: 0;
    .d-flex {
      width: 100%;
    }
  }
  table {
    width: 100%;
    th,
    td {
      text-align: center;
      vertical-align: middle;
    }
    tr {
      th {
        background-color: #f8f8f8;
        padding: 10px;
        border: 1px solid #e8e8e8;
        color: @theme-primary;
        text-transform: uppercase;
        &:nth-child(3) {
          text-align: center;
        }
      }
      td {
        padding: 6px;
        border: 1px solid #e8e8e8;
        white-space: nowrap;
        min-width: 70px;
        position: relative;
        &:hover {
          background-color: @theme-light;
          cursor: pointer;
        }
        .customCheckBox {
          float: left;
        }
      }
      .quickSearchTitleTd {
        background-color: #f8f8f8;
        &:hover {
          background-color: #f8f8f8;
        }
      }
    }
  }
}
.quickSearchMainWrapper {
  display: flex;
  height: 100%;
  .quickSearchLeft {
    width: 360px;
    background: @theme-light;
    padding: 15px 0 0 0;
    position: relative;
    overflow: auto;
    // height: calc(~"100vh - 134px");
    min-height: 100%;
  }
  .quickSearchRight {
    width: calc(~'100% - 360px');
    &.checkImageWrapper {
      padding: 0;
    }
    .quickSearchTable {
      padding: 10px;
    }
  }
}
.pointer-events-none {
  pointer-events: none;
}
