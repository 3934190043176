@import 'variables.less';

.permissionList {
  width: 100%;
}
.permissionLeft {
  width: calc(~'100% - 600px'); //calc(~'100% - 380px');
  h2 {
    margin-bottom: 0 !important;
    // margin-left: 10px;
  }
  img {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
}
.permissionAction {
  width: 600px;
  justify-content: flex-start !important;
  .permissionActionList {
    margin-left: 5px;
    width: 50px;
    h2 {
      margin-bottom: 0;
      margin-left: -15px;
      text-transform: capitalize;
      font-weight: normal;
      font-size: 12px;
    }
    .ant-checkbox-wrapper {
      .ant-checkbox {
        .ant-checkbox-inner {
          width: 15px !important;
          height: 15px !important;
          border-radius: 2px;
          &:after {
            width: 4px;
            height: 7px;
            // border: 1px solid #fff;
          }
        }
      }
    }
  }
}
.permissionList {
  .customcollapselist {
    padding: 10px 0 10px 10px;
    .accordion__button {
      &:before {
        // position: relative;
        left: -15px;
      }
    }
  }
  &.submenuSingle {
    padding: 10px 0 10px 10px;
  }
}
.permissionWrapper {
  height: calc(~'100vh - 280px');
  overflow: auto;
  padding: 0 10px;
  .menuInnerBlock {
    padding: 0;
    margin-top: 10px;
  }
  .permissionHead {
    background-color: #fafafa;
    padding: 10px 15px 10px 10px;
  }
  .collpaseMenuAccess {
    &.permissionList {
      padding: 0 15px;
    }
  }
}
// .caratRangeWrapper {
//   position: relative;
// }
.userPermissionAction {
  padding: 10px;
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
  background: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  .commonOutine {
    margin-left: 10px;
  }
}
.userListBlockWrapper {
  display: flex;
  flex-wrap: wrap;
  .from-group {
    width: calc(~'33.33% - 10px');
    padding: 0 5px;
  }
}
.userInformation {
  padding: 15px;
  // height: calc(~'100vh - 225px');
  background-color: @theme-bg;
}
.userChangePassword {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  .from-group {
    width: calc(~'50% - 10px');
    padding: 0 5px;
  }
}
