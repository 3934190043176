@import 'variables.less';

.diamondDetailPopup {
  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1 !important;
  }

  .ant-drawer-header-no-title {
    .ant-drawer-close {
      background: #fff;
      right: auto;
      left: -46px;
      border-radius: 20px;
      top: 8px;
      width: 40px;
      height: 40px;
      line-height: 42px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-drawer-content-wrapper {
    width: 84% !important;
    padding: 0;
    height: 100%;

    .ant-drawer-body {
      width: 100%;
      height: 100%;

      .diamondDetailWrapper {
        width: 100%;
        height: 100%;

        .diamondDetailTopTab {
          position: absolute;
          left: 0;
          top: 17px;
          border-bottom: none;
          z-index: 10000;
        }

        .diamondDetailInner {
          .diamondDetailMainTab {
            display: flex;
          }

          .diamondDetailRight {
            width: 50%;
            max-height: calc(~'100vh - 131px');
            overflow: auto;
            position: relative;
            z-index: 1;

            .diamondDetailMore {
              padding: 20px;

              .detailSmallTitle {
                font-size: 18px;
                color: #424242;
                margin-bottom: 0;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 1px;
                margin-bottom: 20px;
              }

              .diamondDetailMoreHalf {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                ul {
                  width: 49%;
                  padding: 0;

                  li {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-bottom: 10px;

                    span {
                      color: #a0a1a7;
                      font-size: 14px;
                      font-weight: normal;

                      &:first-child {
                        width: 115px;
                        margin-right: 5px;
                        font-size: 12px;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                      }

                      &:last-child {
                        font-size: 16px;
                        color: #424242;
                        word-break: break-word;
                        width: calc(~'100% - 120px');
                      }
                    }
                  }
                }

                &.diamondFullBlock {
                  ul {
                    width: 100%;

                    li {
                      span {
                        &:first-child {
                          width: 190px;
                        }

                        &:last-child {
                          width: calc(~'100% - 195px');
                        }

                        &.addNotesOption {
                          display: flex;
                          align-items: center;

                          textarea {
                            width: calc(~'100% - 110px');
                            border: 1px solid #e2e2e2;
                            background-color: #fff;
                            height: 80px;
                            border-radius: 5px;
                            margin-right: 10px;
                            padding: 10px;

                            &:focus {
                              outline: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .diamondDetailLeft {
            width: 50%;
            position: relative;
            z-index: 1;
            height: calc(~'100vh - 131px');
            overflow: auto;

            .diamondDetailInnerDetail {
              .react-tabs__tab-panel {
                .diamondDetailImageBox {
                  height: 500px;
                  padding: 20px 10px;
                  text-align: center;
                  img,
                  iframe {
                    width: 100%;
                    height: 100%;
                    margin: auto;
                    object-fit: contain;
                    border: none;

                    &.certificateImage {
                      height: auto;
                    }
                  }

                  .imageBlock {
                    max-width: 100%;
                    height: 100% !important;

                    img {
                      width: 90%;
                      height: 100%;
                    }
                  }
                }
              }
            }
          }

          .diamondAllPrice {
            // border: 1px solid #e2e2e2;
            align-items: center;
            background: #f7f9ff;
            border: 1px dashed #cee3fe;
            padding: 10px;
            margin: 10px 10px 0 10px;
            // display: flex;
            /* box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09); */
            position: relative;
            // background: #fff;
            border-radius: 10px;
            justify-content: space-between;
            .askingPriceBlock,
            .listPriceBlock,
            .m2mPriceBlock {
              display: flex;

              .priceBlockItem {
                display: flex;
                margin-bottom: 5px;
                margin-right: 10px;
                align-items: center;

                span {
                  font-size: 14px;

                  &:first-child {
                    margin-right: 5px;
                    color: #555;
                    text-transform: uppercase;
                    font-size: 12px;
                  }

                  &:last-child {
                    font-weight: 600;
                  }
                }
              }

              &.m2mPriceBlock {
                .priceBlockItem {
                  margin-bottom: 0;
                }
              }
            }
          }

          .basicDetailFull {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 12px;
            margin-bottom: 10px;

            li {
              color: #212431;
              font-size: 20px;
              font-weight: 600;
              list-style: none;
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

.detailHalfWhite {
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: #fff;
}

.detailHalfGray {
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  background-color: #f6f6f6;
}

.diamondDetailClient {
  display: flex;
  padding-top: 10px;
  align-items: center;

  .diamondClientSerach {
    width: 35%;
  }
}

.diamondClientSerach {
  background: @theme-input-light;
  border: 1px solid #e3caaf;
  padding: 5px 5px 5px 15px;
  border-radius: 10px;
  margin-bottom: 0;
  // width:35%;
  width: 260px;
  margin-right: 10px;

  input {
    background-color: transparent;
    padding-left: 0;
    color: #000;
    border: none;

    &::placeholder {
      color: #000;
    }

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  .ant-input-suffix {
    svg {
      fill: @theme-primary;
      width: 20px;
      height: 20px;
    }
  }
}

.diamondClientDetail {
  border: 1px solid #e2e2e2;
  width: calc(100% - 35%);
  padding: 12px 12px;
  border-radius: 10px;
  background: #f6f6f6;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;

  .diamondClientRightBorder {
    border-right: none !important;
    // padding-right: 5px;
    // margin-right: 5px;
  }

  .separeter {
    background: #e2e2e2;
    width: 1px;
    height: 15px;
  }
}

.diamondDetailSummary {
  width: 100%;

  .botoomStickyBar {
    display: none !important;
  }
}

.analyticsWrapper {
  padding: 10px;
  width: 100%;

  p.offerHistroyDec {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
    width: 200px;
  }

  .offerHistroy,
  .similarStoneSale {
    border: 1px solid #e3caaf;
    border-radius: 10px;
    margin-top: 20px;

    .analtaticsTitle {
      padding: 10px;
      width: 100%;
      display: block;
      font-size: 22px;
      margin-right: 10px;
      color: #424242;
      margin-bottom: 0;
      text-transform: capitalize;
      font-weight: 600;
      letter-spacing: 1px;
      background: @theme-bg;
      border-radius: 10px 10px 0 0;
    }

    .searchResultTable {
      height: 300px;

      &::-webkit-scrollbar-thumb {
        background-color: #ddd !important;
      }

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background: #fff !important;
      }
    }
  }
}

.cardBlockItem {
  width: 200px;
  border-radius: 15px;
  padding: 15px;
  background-color: #f2a751;
  margin-right: 10px;

  &.cardView2 {
    background-color: #eeac9d;
  }

  &.cardView3 {
    background-color: #2d2e46;
  }

  &.cardView4 {
    background-color: @theme-primary;
  }

  .cardBlockTitle {
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.5px;
    margin-bottom: 15px;
  }

  .cardBlockCount {
    font-size: 48px;
    color: #fff;
    line-height: 50px;
  }
}

.gia-cer-color {
  color: #edb41b !important;
}

.ige-cer-color {
  color: #927559 !important;
}

.other-cer-color {
  color: #339cd4 !important;
}

.bid-winner {
  background-color: #cae9b9 !important;
}

.bid-second-winner {
  background-color: #99badd8f !important;
}
