@import 'variables';
@import 'fonts';
@import 'scrollbars';
@import 'sidebar';
@import 'custom';
@import 'Inventory.less';
@import 'client.less';
@import 'offer.less';
@import 'reports.less';
@import 'searchResult.less';
@import 'inventoryConfiguration.less';
@import 'configuration.less';
@import 'diamondDetail.less';
@import 'savedSearch.less';
// @import 'list.less';
@import 'notes.less';
@import 'quickSearch.less';
@import 'notification.less';
@import 'Profile.less';
@import 'contactDirectory.less';
@import 'compare.less';
@import 'OffceSidebar.less';
@import 'settings.less';
@import 'notificationSingle.less';
@import 'dashboard.less';
@import 'document.less';
@import 'menuPermission.less';
@import 'user.less';
@import 'listingSidebar.less';
@import 'transcation.less';
@import 'KYCList.less';
@import 'Shortcut.less';
@import 'TableExpand.less';
@import 'todo.less';
@import 'EmailSnyc.less';
@import 'dna.less';
@import '../../node_modules/antd/dist/antd.less';

body {
  margin: 0;
  color: #545454;
  font-size: 14px;
  font-family: 'gilroy';
  font-variant: tabular-nums;
  line-height: 1.3;
  background-color: #fafafa;
  font-feature-settings: 'tnum', 'tnum';
}

@white: #FFFFFF;@black: #000000;@theme-red: #DB1C1C;@theme-green: #469C1A;@theme-blue: #296CB1;@theme-yellow: #D8C803;@theme-primary: #333333;@theme-secondary: #cccccc;@theme-light: #cccccc;@theme-border-dark: #333333;@theme-bg: #F4F7FD;@theme-bg-light: #EDF1FB;@theme-border-light-grey: #E9EBF0;@theme-card-bg-light: #E7EEFC;@theme-card-border-dark: #C5CCE6;@theme-enquiry-light: #D7F0D0;@theme-enquiry: #6BC950;@theme-head-hover: #E5E5F1;@theme-input-light: #DCE6FC;@theme-kyc-light: #EFCCCC;@theme-kyc: #C95050;@theme-left-opt-label: #333333;@theme-left-opt-light: #C9D0EB;@theme-light-border: #E5E5E5;@theme-light-grey: #F8F8F8;@theme-sales-light: #FFEEB2;@theme-sales: #FFC600;@theme-table-border: #FAFAFA;@theme-table-box-shadow: undefined;@theme-table-row-select: #E9EAE9;