@import 'variables.less';

.singleNotificationWrapper {
  padding: 15px;
  background-color: @theme-bg;
  overflow: auto;
  height: calc(~'100vh - 46px');
}
.singleNotification {
  box-shadow: @theme-table-box-shadow;
  border: 1px solid @theme-table-border;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 15px;
  .singleNotifiTitle {
    margin-bottom: 10px;
    h2 {
      margin-left: 2px;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 600;
    }
    .notificationClear {
      width: 20px;
      height: 20px;
      background-color: @theme-primary;
      border-radius: 100%;
      display: flex;
      cursor: pointer;
      img {
        margin: auto;
        width: 12px;
        height: 12px;
      }
    }
  }
  .singleNotifiDetail {
    .singleNotifiTop {
      .singleNoifitiUser {
        font-size: 15px;
        color: #000;
      }
      .singleNotifiTime {
        color: #232323;
      }
    }
    .singleNotifiStatusItem {
      margin-top: 10px;
      border-top: 1px solid @theme-bg;
      padding-top: 10px;
      .singlenotifistatusTitle {
        text-transform: uppercase;
        font-size: 12px;
        color: #555;
        margin-right: 15px;
      }
      .singlenotifiStatus {
        img {
          width: 14px;
          height: 14px;
          margin: 0 15px;
        }
      }
    }
  }
}
.sendNotificationBlockLeft {
  .ant-select-open {
    .ant-select-selection__placeholder {
      opacity: 0.5;
    }
    .ant-select-selection-selected-value {
      display: none !important;
    }
    .ant-select-search--inline {
      .ant-select-search__field {
        width: -webkit-fill-available !important;
      }
    }
  }
  .locationDropdown {
    margin-right: 15px;
    .SendNotification {
      width: 33.33%;
      .leftSelectItem {
        margin-right: 0;
      }
    }
  }
}
.sendNotification {
  height: 100%;
  .sendNotificationBlockLeft {
    background-color: @theme-light;
    width: 360px;
    height: 100%;
    .SendNotification {
      padding: 15px 0 0 0;
      &.checkbox {
        padding-left: 15px;
        padding-bottom: 10px;
      }
    }
  }
  .sendNotificationRight {
    background-color: @theme-bg;
    width: calc(~'100% - 360px');
    height: 100%;
    position: relative;
    .sendNotificationCol {
      padding: 15px 15px 60px 15px;
      .from-group {
        width: calc(~'50% - 10px');
        padding-right: 10px;
        input,
        .commonTextArea {
          background-color: transparent;
        }
      }
    }
    .sendButton {
      box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
      background: #fff;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      padding: 10px;
    }
  }
}
