@import 'variables.less';

.dashboardMainBox {
  .customerMaintab {
    position: sticky;
    top: 0;
    z-index: 10;
    background: #fff;
  }
}
.dashboardItem {
  position: relative;
  width: calc(~'100% - 10px');
  margin: 5px;
  height: auto;
  border: none !important;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  &.halfUp {
    width: calc(~'60% - 10px');
  }
  &.halfDown {
    width: calc(~'40% - 10px');
  }
}
.dashboardBox {
  display: flex;
  background-color: @theme-bg;
  padding: 10px;
  .dashboardLeft {
    width: calc(~'100% - 420px');
    // height: 100vh;
    // display: grid;
    .dashboardTop {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .dashboardRight {
    width: 420px;
    padding-left: 5px;
    .dashboardItemHead {
      margin: 0;
      background-color: #fff;
      padding: 10px;
      border-radius: 10px 10px 0 0;
    }
    .rightSticky {
      position: sticky;
      // top: 53px;
      .dashboardOption {
        width: 120px;
      }
    }
  }
}
.recentActivityOption.dashboardOption {
  width: 150px !important;
}
.StockSummary {
  width: 100%;
  padding: 15px 0;
  .totalEnquiryBlock {
    padding: 0;
  }
}
.dashboardItemHead {
  padding: 5px 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  h2 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  .handle_block {
    display: flex;
    align-items: center;
    .from-group.inputBlock {
      width: 50%;
      margin-right: 20px;
      .inputWrapper input {
        background: transparent;
      }
    }
    button {
      background-color: #fff;
      color: @theme-primary;
      border: 1px solid @theme-primary;
      cursor: pointer;
      border-radius: 5px;
      padding: 10px;
      &:hover {
        background-color: @theme-primary;
        color: #fff;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .dashboardOption {
    // width: 100px;
    .ant-select {
      border: none;
      width: 100%;
      // border-bottom: 1px solid #d9d9d9;
      .ant-select-selection {
        border: none;
        width: auto;
      }
    }
  }
  .dateOption {
    span {
      &:first-child {
        margin-right: 5px;
        font-weight: 500;
      }
    }
  }
}
.todayAppointmentBlock {
  position: relative;
}
.todayAppointementBlock {
  height: 403px;
  overflow: auto;
  background-color: @theme-bg;
  padding: 10px;
  .todayAppointementTable {
    height: 100%;
  }
  .todayAppointemtnWrapper {
    display: flex;
    justify-content: space-between;
    .todayAppointementItem {
      width: calc(~'100% / 5');
      border-top: 1px solid #e2e2e2;
      padding: 8px 5px;
      span {
        display: block;
        &:first-child {
          font-size: 11px;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          margin-bottom: 5px;
        }
        &:last-child {
          font-size: 14px;
          font-weight: 500;
          color: #000;
        }
      }
    }
  }
}
.dashboardTableWrapeper {
  padding: 10px;
  background-color: @theme-bg;
}
.newInventoryListWrapper {
  height: 161px;
}
.newInventoryList {
  // overflow: auto;
  table {
    width: 100%;
    tr {
      th {
        border: none !important;
        &:first-child {
          position: sticky;
          background-color: @theme-primary !important;
          left: 0;
          border-right: 1px solid #e2e2e2;
          color: @white-color;
        }
        &:last-child {
          position: sticky;
          background-color: @theme-primary !important;
          right: 0;
          border-left: 1px solid #e2e2e2;
          color: @white-color;
        }
      }
      td {
        border: none !important;
        background-color: transparent !important;
        &:first-child {
          background-color: #fff !important;
          position: sticky;
          left: 0;
          border-right: 1px solid #e2e2e2;
          color: #000;
          font-weight: 600;
        }
        &:last-child {
          position: sticky;
          background-color: #fff !important;
          right: 0;
          border-left: 1px solid #e2e2e2;
        }
      }
    }
  }
}
.todayAppointementTable {
  table {
    width: 100%;
    tr {
      th,
      td {
        &:first-child {
          width: 70px;
        }
      }
    }
  }
}
.dashboardTable {
  table {
    width: 100%;
    tr {
      th,
      td {
        padding: 10px 5px;
        background-color: transparent !important;
        text-align: center;
        border-right: none !important;
        // white-space: nowrap;
        &:first-child {
          width: 70px;
        }
      }
      th {
        // background-color: #ffe3b2 !important;
        background-color: @theme-light !important;
        line-height: 16px;
        font-size: 12px;
        text-transform: uppercase;
        border-radius: 0px;
        padding: 3px 5px;
        white-space: nowrap;
        width: auto;
        font-weight: 600;
        color: #000;
        // color: @theme-primary;
        letter-spacing: 0.5px;
      }
      td {
        border-right: 1px solid #f2f2f2;
      }
    }
    table {
      tr {
        td {
          &:first-child {
            width: 250px;
          }
        }
      }
    }
    thead {
      tr {
        background-color: @theme-light !important;
      }
    }
    tbody {
      tr {
        box-shadow: @theme-table-box-shadow;
        background-color: #fff;
        border: 1px solid @theme-table-border;
        border-right: none !important;
      }
    }
  }
  &.overflow-auto {
    overflow: auto;
  }
  &.stockSummaryTable {
    overflow: auto;
    table {
      tbody {
        tr {
          th,
          td {
            text-align: left;
          }
          td {
            &:first-child {
              width: 50px;
              min-width: 50px;
              max-width: 50px;
            }
          }
        }
      }
    }
  }
  &.jamesallenTable {
    overflow: auto;
  }
}
.jemsStockText {
  p {
    color: #000;
  }
}

.RecentActivityBlock {
  height: calc(~'100vh - 124px');
  overflow: auto;
}
.RecentActivityBlockItem {
  background: @theme-bg;
  border-radius: 0 0 10px 10px;
}
.recentActivity {
  ul {
    .ant-select-dropdown-menu-item {
      display: flex;
      align-items: center;
    }
  }
}
.recentActivityItem {
  box-shadow: @theme-table-box-shadow;
  background-color: #fff;
  border: 1px solid @theme-table-border !important;
  padding: 10px;
  .recentItemTitle {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    span.recentTitle {
      width: calc(~'100% - 13px');
    }
  }
  .recentItemDetail {
    display: flex;
    .detail {
      margin-right: 10px;
      span {
        font-size: 14px;
        &:first-child {
          text-transform: uppercase;
          font-size: 12px;
          margin-right: 10px;
        }
        &:last-child {
          font-weight: 600;
        }
      }
    }
  }
  .recentActivityDetail {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    .recentActivityUser {
      h2 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
    .recentActivitytime {
      span {
        font-size: 13px;
      }
    }
  }
}
.activityType {
  width: 8px;
  height: 8px;
  border-radius: 2px;
  display: block;
  background: #6bc950;
  margin-right: 5px;
  &.sales {
    background: #6bc950;
  }
  &.enquiry {
    background: #5076c9;
  }
  &.kyc {
    background: #c95050;
  }
  &.gia {
    background: #ffc600;
  }
  &.cart {
    background: #c25d22;
  }
  &.confirm {
    background: #318f19;
  }
  &.search {
    background: #219dcc;
  }
  &.stoneId {
    background: @theme-primary;
  }
}

.dashboardThreeBlock {
  display: flex;
  flex-wrap: wrap;
}
.revenuHeight {
  height: 560px !important;
}
.todayVisitiGraph {
  height: 350px !important;
}
.canvasjs-chart-credit {
  display: none;
}
.halfBlock {
  width: calc(~'50% - 10px') !important;
}
.viewAllPageBlock {
  width: 100%;
  padding: 8px 0;
  background-color: #fff;
  color: @theme-primary;
  text-align: center;
  border-top: 1px solid #ddd;
  cursor: pointer;
  span {
    &:first-child {
      font-size: 14px;
    }
    &:last-child {
      font-size: 20px;
    }
  }
  &.positionFix {
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 10px 10px;
    padding: 10px 0;
  }
}
.todayappointmentTotal {
  display: flex;
  .detail {
    display: flex;
    align-items: center;
    margin-left: 10px;
    .detailImage {
      margin-right: 3px;
      width: 28px;
      height: 28px;
      border: 1px solid #ddd;
      border-radius: 100%;
      display: flex;
      img {
        margin: auto;
        width: 18px;
        height: 18px;
      }
    }
    span {
      font-size: 13px;
      line-height: 16px;
      &:last-child {
        margin-left: 5px;
        font-weight: bold;
      }
    }
    &.callDetail {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.viewAllSecond {
  border-top: 1px solid #ddd;
  width: 100%;
  .viewAllPageBlock {
    width: auto;
    border: none;
  }
}
// Price Change
.priceChangeBlock {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .priceChangeBlockItem {
    width: calc(~'50% - 10px');
    border: 1px solid @theme-primary;
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    span {
      display: block;
      &:first-child {
        margin-bottom: 10px;
      }
      &:last-child {
        font-weight: 600;
        color: #000;
        font-size: 26px;
      }
    }
  }
}
// Enquiry
.openItemWrapper {
  display: flex;
  .openItemItem {
    width: calc(~'100% / 6');
    border: 1px solid @theme-primary;
    margin: 0 5px;
    padding: 10px;
    border-radius: 10px;
    span {
      display: block;
      &:first-child {
        margin-bottom: 5px;
      }
      &:last-child {
        font-weight: 600;
        font-size: 20px;
        color: #000;
      }
    }
  }
}
.enquirySecondBlock {
  display: flex;
}
.conversationList {
  display: flex;
  justify-content: center;
  span {
    min-width: 55px;
    margin: 0 5px;
    text-align: center;
  }
}
.conversationListHead {
  margin-top: 10px;
  span {
    min-width: 55px;
    font-weight: 600;
    color: #000;
  }
}
.conversationTable {
  table {
    tr {
      td,
      th {
        &:first-child {
          min-width: min-content;
          font-weight: 600;
          color: #000;
        }
      }
      th {
        &:first-child {
          .conversationListHead {
            display: none !important;
          }
        }
      }
      td {
        &:last-child {
          font-weight: 600;
          color: #000;
        }
      }
    }
  }
}
.totalEnquiryTable {
  table {
    td,
    th {
      &:first-child {
        font-weight: 600;
        color: #000;
      }
    }
  }
}
.totalEnquiryBlock {
  margin-top: 20px;
  background: #fff;
  padding: 5px 10px 10px 10px;
}
// Inventory
.rightSticky {
  .rightStickyTab {
    display: block;
    width: 100%;
    border-radius: 10px 10px 0 0;
    background: #fff;
    ul {
      padding: 0;
      display: flex;
      margin: 0;
      width: 100%;
    }
  }
  .rightStickyScroll {
    height: calc(~'100vh - 153px');
    overflow: auto;
    background: #fff;
  }
  .ageingBlock {
    .filterOption {
      background-color: #fff;
      padding: 15px 10px;
      .leftMultiSelect {
        margin-bottom: 0;
      }
    }
    .ant-select-selection--multiple {
      .ant-select-selection__rendered {
        line-height: 5px;
      }
    }
    .ageingBlockItem {
      box-shadow: @theme-table-box-shadow;
      background-color: #fff;
      border: 1px solid @theme-table-border !important;
      padding: 10px;
      .ageingBlockHead {
        font-size: 16px;
        font-weight: 600;
      }
      .ageingBlockDetail {
        display: flex;
        .detail {
          margin-right: 10px;
          span {
            font-size: 14px;
            &:first-child {
              text-transform: uppercase;
              font-size: 12px;
              margin-right: 10px;
            }
            &:last-child {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .ageingFilterBlock {
    background-color: @theme-primary;
    color: #fff;
    padding: 10px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    border-radius: 0 0 10px 10px;
    letter-spacing: 0.5px;
    cursor: pointer;
  }
}
// cardBox
.cardBoxValueWrapper {
  .cardBlockValueItem {
    display: flex;
    flex-wrap: wrap;
    .detail {
      width: calc(~'50% - 10px');
      margin: 5px;
      padding: 10px;
      border-radius: 10px;
      background-color: #fff;
      border: 1px solid @theme-primary;
      span {
        display: block;
        font-size: 14px;
        &:first-child {
          margin-bottom: 10px;
          font-size: 16px;
        }
        &:last-child {
          font-size: 36px;
          color: #000;
          font-weight: 600;
        }
      }
      &.fullBlock {
        width: 100%;
      }
    }
    &.cardSmall {
      .detail {
        span {
          &:first-child {
            margin-bottom: 5px;
            font-size: 14px;
          }
          &:last-child {
            font-size: 22px;
          }
        }
      }
    }
  }
}
// InventoryOverView
.InvnetoryOverView {
  border-top: 2px solid #f3f7fc;
  padding-top: 10px;
  .InventoryBlockItem {
    .dashboardItemHead {
      margin-bottom: 5px;
      h2 {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.8px;
      }
    }
  }
  .cardBoxValueWrapper {
    .cardBlockValueItem {
      .detail {
        margin: 5px 0;
      }
    }
  }
}
.smallBoxCard {
  .cardBoxValueWrapper {
    width: 25%;
  }
}
.enquiryCardBlockDetail {
  .enquiryParameter {
    .title {
      margin-right: 10px;
      text-transform: uppercase;
      font-weight: 500;
      color: #555;
    }
    .detailBlock {
      .detail {
        position: relative;
        margin: 3px 12px 3px 0;
        span {
          &:first-child {
            text-transform: uppercase;
            font-size: 12px;
            margin-right: 10px;
          }
          &:last-child {
            font-weight: 600;
          }
        }
        &:after {
          content: '';
          position: absolute;
          right: -6px;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #c4c4c4;
          width: 1px;
          height: 12px;
        }
        &:last-child {
          &:after {
            content: none;
          }
        }
      }
    }
  }
}
.userSearchWrapper {
  display: flex;
  .userSearchLeft {
    width: 360px;
    background-color: @theme-light;
    padding: 15px;
    border-radius: 10px;
  }
  .userSearchRight {
    width: calc(~'100% - 360px');
    padding-left: 25px;
  }
}
.searchingHabitesWrapper {
  table {
    width: 100%;
    tr {
      td {
        padding: 7px 0;
        &:first-child {
          font-weight: 600;
        }
        .habitDots {
          width: 24px;
          height: 24px;
          background: @theme-light;
          border-radius: 50%;
          margin: auto;
          &.active {
            background-color: @theme-primary;
          }
        }
      }
      &:last-child {
        td {
          text-align: center;
        }
      }
    }
  }
}
.searchHabitesDetail {
  .details {
    margin: 8px 0;
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      &:first-child {
        margin-right: 5px;
      }
      &:last-child {
        font-weight: 600;
        font-size: 15px;
      }
    }
  }
}
// div#height {
//     height: 100%;
// }
.headerstrip {
  margin: 10px 0;
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 100;
  display: flex;
  .headerStripItem {
    margin-right: 10px;
    span {
      color: #000;
      &:first-child {
        margin-right: 5px;
        font-size: 13px;
      }
    }
    .headerStripValue {
      padding: 2px;
      border: 1px solid @theme-primary;
      border-radius: 3px;
      padding: 2px 4px;
      color: @theme-primary;
      font-size: 13px;
    }
  }
}
