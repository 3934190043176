@import './variables.less';

.smallScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: @theme-primary !important;
}

.grayScroll::-webkit-scrollbar {
  background: #000 !important;
  &-thumb {
    background: #ddd !important;
  }
}

.smallGrayScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #a1a1a1 !important;
  &-thumb {
    background: #6b6b6b !important;
  }
}

.themeGrayScroll {
  &.scrollarea {
    .scrollbar-container {
      background: #a1a1a1 !important;
      width: 5px;
      opacity: 1;
      z-index: 0;
      .scrollbar {
        margin-left: 0 !important;
        background: #6b6b6b !important;
        width: 5px !important;
      }
    }
  }
}

.scrollbar-container.horizontal {
  height: 4px !important;
  .scrollbar {
    height: 4px !important;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #ddd !important;
  border-radius: 100px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }

  &-thumb {
    background-color: #999 !important;

    &:vertical {
      border-radius: 100px;

      &:active {
        border-radius: 100px;
      }
    }
  }
}
